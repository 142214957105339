<template>
  <b-card
    v-if="data"
    class="earnings-card"
  >
    <b-row>
      <b-col cols="6">
        <b-card-title class="mb-1">
          Earnings
        </b-card-title>
        <div class="font-small-2">
          This Month
        </div>
        <h5 class="mb-1">
          <!--          $4055.56-->
          {{ NumberFormatter(data.earning) }}
        </h5>
        <b-card-text
          v-if="data.upsize > 0"
          class="text-muted font-small-2"
        >
          <!--          <span class="font-weight-bolder">68.2%</span><span> more earnings than last month.</span>-->
          <span class="font-weight-bolder">{{ data.upsize.toFixed(0) }}%</span><span> more earnings than last month.</span>
        </b-card-text>
      </b-col>
      <b-col
        cols="6"
        class="p-0"
      >
        <!-- chart -->
        <vue-apex-charts
          ref="chart"
          height="200"
          :options="chartOptions"
          :series="data.series"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BCardTitle, BCardText,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import { NumberFormatter } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardTitle,
    BCardText,
    VueApexCharts,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      chartOptions: {
        chart: {
          height: 200,
          type: 'radialBar',
        },
        grid: {
          padding: {
            top: -25,
            bottom: -28,
          },
        },
        // series: [],
        colors: [$themeColors.success],
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 15,
            },
            dataLabels: {
              showOn: 'always',
              name: {
                offsetY: -10,
                show: true,
                color: '#888',
                fontSize: '13px',
              },
              value: {
                offsetY: 5,
                color: '#888',
                fontSize: '22px',
                show: true,
              },
            },
          },
        },
        stroke: {
          lineCap: 'round',
        },
        labels: ['Progress'],
      },
    }
  },
  computed: {
    skin() {
      return this.$store.state.appConfig.layout.skin
    },
  },
  watch: {
    skin(newSkin) {
      this.chartOptions.plotOptions.radialBar.dataLabels.value.color = this.TextColor(newSkin)
      this.$refs.chart.updateOptions(this.chartOptions, false, true)
    },
  },
  methods: {
    NumberFormatter,
    TextColor(color) {
      return {
        dark: '#D0D4DB',
        light: '#5e5873',
      }[color]
    },
  },
}
</script>
